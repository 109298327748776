import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { makeApiRequest } from "../../../utils/api";
import { useVendor } from "../../../providers/VendorContext";
import { FormField } from "../../../types/FormField";
import { VendorForm } from "../../../types/VendorForm";
import {
  ChevronDoubleLeftIcon,
  PlusIcon,
  DocumentIcon,
  PlusCircleIcon,
  PencilIcon,
  TrashIcon,
  ChartPieIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import { VendorCard } from "../../../components/reusable/VendorCard";
import RichTextEditor from "../../../components/RichTextEditor";
import { Button } from "../../../components/reusable/Button";
import { Dialog, Transition } from "@headlessui/react";
import { useNotification } from "../../../providers/NotificationContext";
import { sanitizeHTML } from "../../../utils/sanitizeHTML";
import { Option, SelectInput, TextAreaInput, TextInput } from "../../../components/reusable/Form";
import { useNavigate } from "react-router-dom";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";


const FIELD_TYPES: Option[] = [
  { value: "text", display: "Text" },
  { value: "textarea", display: "Textarea" },
  { value: "radio", display: "Radio" },
  { value: "checkbox", display: "Checkbox" },
  { value: "select", display: "Select" },
  { value: "html", display: "HTML" },
  { value: "date", display: "Date" },
  { value: "time", display: "Time" },
  { value: "number", display: "Number" },
];

export const ViewForm = () => {
  const { formId } = useParams();
  const { currentOrganization } = useVendor();
  const queryClient = useQueryClient();
  const { showSuccess, showError } = useNotification();

  const { data } = useQuery({
    queryKey: ["vendorForm", formId, currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/vendor_forms/${formId}`,
        params: { organization_id: currentOrganization?.id },
      }),
    enabled: !!formId && !!currentOrganization,
  });

  const vendorForm: VendorForm = data?.data;

  const initialFormFieldState: FormField = {
    id: "",
    vendor_form_id: "",
    field_type: "text",
    content: "",
    required: false,
    options: [],
  };

  const [formField, setFormField] = useState<FormField>(initialFormFieldState);
  const [isEditing, setIsEditing] = useState(false);
  const [deleteField, setDeleteField] = useState<FormField | null>(null);

  const [formFields, setFormFields] = useState<FormField[]>([]);

  // Update formFields when vendorForm data changes
  useEffect(() => {
    if (vendorForm?.form_fields) {
      setFormFields(vendorForm.form_fields);
    }
  }, [vendorForm]);



  const navigate = useNavigate();

  const handleDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const sourceFormField = formFields[source.index];
    const newFormFields = Array.from(formFields);
    newFormFields.splice(source.index, 1);
    newFormFields.splice(destination.index, 0, sourceFormField);

    setFormFields(newFormFields);
    makeApiRequest({
      path: `/vendor/vendor_forms/${formId}/form_fields/${sourceFormField.id}`,
      method: "PUT",
      params: {
        organization_id: currentOrganization?.id,
        vendor_form_id: formId,
        form_field: { sort_order: destination.index },
      },
    });
  };


  const handleViewReport = () => {
    navigate(`/vendor/organizations/${currentOrganization?.id}/reports/vendor-form-report?vendor_form_id=${vendorForm?.id}`);
  };


  const handleFieldChange = (
    field: keyof FormField,
    value:
      | string
      | boolean
      | Array<{
        id?: number;
        form_field_id: number;
        content: string;
        _destroy?: boolean;
      }>
  ) => {
    setFormField((prev: FormField) => ({ ...prev, [field]: value }));
  };

  const handleAddOption = () => {
    setFormField((prev: FormField) => ({
      ...prev,
      options: [
        ...prev.options,
        {
          id: undefined,
          form_field_id: parseInt(formField.id || "0"),
          content: "",
        },
      ],
    }));
  };

  const handleOptionChange = (index: number, value: string) => {
    setFormField((prev: FormField) => {
      const updatedOptions = [...prev.options];
      updatedOptions[index].content = value;
      return { ...prev, options: updatedOptions };
    });
  };

  const handleRemoveOption = (index: number) => {
    setFormField((prev: FormField) => {
      const updatedOptions = [...prev.options];
      const removedOption = updatedOptions[index];

      if (removedOption.id) {
        // Existing option: mark for deletion
        updatedOptions[index] = { ...removedOption, _destroy: true };
      } else {
        // New option: remove directly
        updatedOptions.splice(index, 1);
      }

      return { ...prev, options: updatedOptions };
    });
  };

  const handleSave = () => {
    const apiPath = formField.id
      ? `/vendor/vendor_forms/${formId}/form_fields/${formField.id}`
      : `/vendor/vendor_forms/${formId}/form_fields`;

    const method = formField.id ? "PATCH" : "POST";

    const payload = {
      vendor_form_id: formId,
      content: formField.content,
      field_type: formField.field_type,
      required: formField.required,
      form_field_options_attributes: formField.options,
    };

    makeApiRequest({
      path: apiPath,
      method,
      params: { organization_id: currentOrganization?.id, ...payload },
    })
      .then((res) => {
        if (method === "POST" && res.status === 201 || method === "PATCH" && res.status === 200) {
          queryClient.invalidateQueries({ queryKey: ["vendorForm"] });
          setIsEditing(false);
          setFormField(initialFormFieldState);
          showSuccess("Form field saved successfully!");
        }
      })
      .catch((err) => {
        const error = err.response.data;
        showError(error.message || "An error occurred");
      });
  };

  const handleEdit = (field: FormField) => {
    setIsEditing(true);
    setFormField({
      ...field,
      options: field.options || [],
    });
  };


  const handleDelete = () => {
    if (!deleteField) return;

    makeApiRequest({
      path: `/vendor/vendor_forms/${formId}/form_fields/${deleteField.id}`,
      method: "DELETE",
      params: { organization_id: currentOrganization?.id },
    })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["vendorForm"] });
        setDeleteField(null);
        showSuccess("Form field deleted successfully!");
      })
      .catch((error) => {
        showError(error.response.data.message || "An error occurred");
      });
  };

  const renderPreview = () => {
    if (formField.field_type === "html") return null;

    return (
      <div className="p-5 rounded-lg bg-gray-100">
        {/* Render content label only if the field type is not checkbox */}
        {formField.field_type !== "checkbox" && (
          <div className="text-xl font-semibold text-gray-800 mb-4">
            {formField.content || "Content goes here..."}
          </div>
        )}
        <div className="space-y-3">
          {(() => {
            switch (formField.field_type) {
              case "text":
                return <TextInput name="preview-text" type="text" className="border p-2 w-full rounded" />;
              case "textarea":
                return (
                  <TextAreaInput name="preview-textarea" rows={4} className="border p-2 w-full rounded" />
                );
              case "radio":
                return formField.options
                  .filter((opt) => !opt._destroy)
                  .map((opt, idx) => (
                    <div key={idx} className="flex items-center space-x-2">
                      <input type="radio" id={opt.content} name="preview" />
                      <label htmlFor={opt.content}>{opt.content || "Option"}</label>
                    </div>
                  ));
              case "checkbox":
                return (
                  <div className="flex items-center space-x-2">
                    <input type="checkbox" id={`checkbox-${formField.id}`} />
                    <label htmlFor={`checkbox-${formField.id}`}>
                      {formField.content || "Checkbox"}
                    </label>
                  </div>
                );
              case "select":
                return (
                  <select className="border p-2 w-full rounded">
                    {formField.options
                      .filter((opt) => !opt._destroy)
                      .map((opt, idx) => (
                        <option key={idx}>{opt.content || "Option"}</option>
                      ))}
                  </select>
                );
              case "date":
                return <input type="date" className="border p-2 w-full rounded" />;
              case "time":
                return <input type="time" className="border p-2 w-full rounded" />;
              case "number":
                return <input type="number" className="border p-2 w-full rounded" />;
              default:
                return null;
            }
          })()}
        </div>
      </div>
    );
  };


  const renderFieldPreview = (field: FormField) => {
    if (field.field_type === "html") return null;

    return (
      <div className="mt-2">
        {(() => {
          switch (field.field_type) {
            case "text":
              return (
                <TextInput
                  name="text"
                  type="text"
                  placeholder={field.content}
                  disabled={true}
                />
              );
            case "textarea":
              return (
                <TextAreaInput
                  name="textarea"
                  rows={3}
                  disabled
                ></TextAreaInput>
              );
            case "radio":
              return field.options
                .filter((opt) => !opt._destroy)
                .map((opt, idx) => (
                  <div key={idx} className="flex items-center space-x-2">
                    <input
                      type="radio"
                      id={`radio-${field.id}-${idx}`}
                      name={`radio-${field.id}`}
                      className="form-radio text-blue-600"
                      disabled
                    />
                    <label
                      htmlFor={`radio-${field.id}-${idx}`}
                      className="text-gray-700"
                    >
                      {opt.content || "Option"}
                    </label>
                  </div>
                ));
            case "checkbox":
              return (
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id={`checkbox-${field.id}`}
                    className="form-checkbox text-blue-600"
                    disabled
                  />
                  <label
                    htmlFor={`checkbox-${field.id}`}
                    className="text-gray-700"
                  >
                    {field.content || "Checkbox"}
                    {field.required && <span className="text-red-500"> *</span>}
                  </label>
                </div>
              );
            case "select":
              return (
                <select
                  className="border border-gray-300 shadow-sm p-2 w-full rounded bg-white"
                  defaultValue=""
                >
                  <option value="" disabled hidden>
                    Select
                  </option>
                  {field.options
                    .filter((opt) => !opt._destroy)
                    .map((opt, idx) => (
                      <option key={idx}>{opt.content || "Option"}</option>
                    ))}
                </select>
              );
            case "date":
              return (
                <input
                  type="date"
                  className="border border-gray-300 shadow-sm p-2 w-full rounded bg-white"
                  disabled
                />
              );
            case "time":
              return (
                <input
                  type="time"
                  className="border border-gray-300 shadow-sm p-2 w-full rounded bg-white"
                  disabled
                />
              );
            case "number":
              return (
                <input
                  type="number"
                  className="border border-gray-300 shadow-sm p-2 w-full rounded bg-white"
                  disabled
                />
              );
            default:
              return null;
          }
        })()}
      </div>
    );
  };


  const dynamicOptionsLabel = () => {
    switch (formField.field_type) {
      case "radio":
        return "Add the options for your radio button";
      case "select":
        return "Add the options for your dropdown";
      default:
        return "Answer Options";
    }
  };


  if (!vendorForm) return null;

  return (
    <>
      <Link to={`/vendor/organizations/${currentOrganization?.id}/vendor_forms`}>
        <div className="flex items-center gap-2 text-lightPurple hover:text-purple-700 transition-colors">
          <ChevronDoubleLeftIcon className="w-5 h-5" />
          <span className="font-medium">Back to Forms</span>
        </div>
      </Link>

      <div className="flex justify-between items-center mt-5">
        <h2 className="text-2xl font-semibold text-gray-500">{vendorForm.name}</h2>

        {!isEditing && (
          <div className="flex gap-x-3">
            <Button onClick={handleViewReport} variant="default" size="sm">
              <ChartPieIcon className="w-4 h-4" />
              View Report
            </Button>
            <Button
              onClick={() => setIsEditing(true)}
              variant="blue"
              size="sm"
              className="flex items-center"
            >
              <PlusIcon className="w-4 h-4 mr-1" /> Add Form Field
            </Button>

          </div>
        )}
      </div>


      <VendorCard className="mt-4">
        {isEditing ? (
          <div className="flex flex-wrap">
            <div className="w-full md:w-2/3 p-5">
              <div className="mb-6 max-w-sm">
                <label className="block font-bold">Choose the type of form field</label>
                <SelectInput
                  name="field_type"
                  options={FIELD_TYPES}
                  onSelect={(e) => handleFieldChange("field_type", e.value)}
                  className="border p-2 w-1/3 rounded mt-2"
                />
              </div>
              <div className="mb-6 max-w-sm">
                <label className="block font-bold mb-2">Enter the label or content for your form field</label>
                {formField.field_type === "html" ? (
                  <RichTextEditor
                    value={formField.content}
                    onChange={(value) => handleFieldChange("content", value)}
                  />
                ) : (
                  <TextInput
                    name="content"
                    placeholder="Enter the label or content"
                    type="text"
                    autoComplete="off"
                    value={formField.content}
                    onChange={(e) => handleFieldChange("content", e.target.value)}
                    className="border p-2 w-full rounded"
                  />
                )}
              </div>
              {["radio", "select"].includes(formField.field_type) && (
                <div className="mb-3">
                  <h3 className="font-bold">{dynamicOptionsLabel()}</h3>
                  {formField.options.map((opt, idx) => (
                    !opt._destroy && (
                      <div key={idx} className="flex items-center gap-2 mb-2 max-w-sm">
                        <TextInput
                          name={"option" + idx}
                          type="text"
                          value={opt.content}
                          autoComplete="off"
                          onChange={(e) => handleOptionChange(idx, e.target.value)}
                        />
                        <button
                          onClick={() => handleRemoveOption(idx)}
                          className="text-red-500"
                        >
                          <TrashIcon className="w-4 h-4" />
                        </button>
                      </div>
                    )
                  ))}
                  <button
                    onClick={handleAddOption}
                    className="bg-lightPurple text-white px-3 py-1 rounded"
                  >
                    Add Option
                  </button>
                </div>
              )}

              {formField.field_type !== "html" && (
                <div className="mb-6 flex items-center space-x-2 mt-6">
                  <input
                    type="checkbox"
                    id="required"
                    checked={formField.required}
                    onChange={(e) => handleFieldChange("required", e.target.checked)}
                    className="form-checkbox h-5 w-5 text-blue"
                  />
                  <label htmlFor="required" className="text-gray-500 font-bold">
                    Require a response
                  </label>
                </div>
              )}
              <div className="mt-16">
                <button
                  onClick={handleSave}
                  className="bg-green-500 text-white px-4 py-2 rounded mt-5"
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    setIsEditing(false);
                    setFormField(initialFormFieldState);
                  }}
                  className="ml-2 px-4 py-2 rounded text-red-500"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="w-full md:w-1/3 p-5 border-t md:border-t-0 md:border-l">
              <h3 className="font-bold mb-4">Preview</h3>
              {renderPreview()}
            </div>
          </div>
        ) : formFields?.length === 0 ? (
          <div className="w-full flex items-center justify-center py-10">
            <div className="max-w-md flex flex-col items-center gap-4 text-center">
              <div className="relative">
                <DocumentIcon className="h-12 text-gray-300" />
                <PlusCircleIcon className="h-6 text-gray-500 absolute right-0 bottom-0" />
              </div>
              <span className="text-lg font-semibold">No Form Fields Yet</span>
              <span className="text-sm text-gray-600">
                Add a new form field to start building your form!
              </span>
              <Button
                onClick={() => setIsEditing(true)}
                variant="blue"
                size="md"
                className="mt-4 flex items-center"
              >
                <PlusIcon className="w-4 h-4 mr-1" /> Add Form Field
              </Button>
            </div>
          </div>
        ) : (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="formFields">
              {(provided) => (
                <div
                  className="sm:mx-20 mx-0 space-y-2 rounded-lg border"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {formFields.map((field: FormField, index) => (
                    <Draggable key={field.id} draggableId={String(field.id)} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="hover:bg-gray-100"
                        >
                          <div
                            className="p-4 flex flex-row items-center border-b mx-5"
                          >
                            {/* Drag handle (vertically centered on the left) */}
                            <div
                              {...provided.dragHandleProps}
                              className="cursor-grab flex items-center mr-4"
                            >
                              <ChevronUpDownIcon className="w-6 h-6 text-gray-400" />
                            </div>

                            <div className="flex-1">
                              {field.field_type === "html" ? (
                                <div
                                  className="block text-gray-700 font-semibold mb-2"
                                  dangerouslySetInnerHTML={{
                                    __html: sanitizeHTML(field.content),
                                  }}
                                />
                              ) : field.field_type !== "checkbox" ? (
                                <label className="block text-gray-700 font-semibold mb-2">
                                  {field.content}
                                  {field.required && <span className="text-red-500">*</span>}
                                </label>
                              ) : null}
                              {renderFieldPreview(field)}
                            </div>

                            {/* Actions */}
                            <div className="flex flex-col space-y-2 ml-4">
                              <Button onClick={() => handleEdit(field)} size="sm" title="Edit Field">
                                <PencilIcon className="w-4 h-4" /> Edit
                              </Button>
                              <Button
                                onClick={() => setDeleteField(field)}
                                variant="error"
                                size="sm"
                                title="Delete Field"
                              >
                                <TrashIcon className="w-4 h-4" /> Delete
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

        )}
      </VendorCard>

      {deleteField && (
        <Transition appear show={!!deleteField} as="div">
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={() => setDeleteField(null)}
          >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as="div"
                className="fixed inset-0 bg-black bg-opacity-30"
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              />
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as="div"
                className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Delete Form Field
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Are you sure you want to delete this form field? This
                    action cannot be undone.
                  </p>
                </div>
                <div className="mt-4 flex justify-end space-x-3">
                  <Button
                    onClick={() => setDeleteField(null)}
                  >
                    Cancel
                  </Button>
                  <Button variant="error" onClick={handleDelete}>
                    Delete
                  </Button>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  );
};
