import { useQuery } from "@tanstack/react-query";
import { useVendor } from "../../../providers/VendorContext";
import { makeApiRequest } from "../../../utils/api";
import { User } from "../../../types/User";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Link } from "react-router-dom";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems
} from "@headlessui/react";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Spinner } from "../../../components/reusable/Spinner";
import { isEqual } from "lodash";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { TextInput } from "../../../components/reusable/Form";
type SearchFormQuery = {
  email: string;
  name: string;
  gender: string | null;
};

type GenderDisplayMap = {
  [key: string]: string;
};
export const CustomerList = () => {
  const [page, setPage] = useState<number>(1);
  const [userName, setUserName] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [gender, setGender] = useState<string | null>(null);

  const [error, setError] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<SearchFormQuery>();

  const columnHelper = createColumnHelper<User>();
  const { currentOrganization } = useVendor();
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["customers", currentOrganization, searchQuery, page],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/organizations/${currentOrganization?.id}/customers`,
        params: {
          page,
          per_page: 50,
          organization_id: currentOrganization?.id,
          search: searchQuery,
        },
      }),
    enabled: !!page && !!currentOrganization,
    retry: false,
  });
  const customers = data?.data.customers;
  const pagination = data?.data.pagination;
  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("full_name", {
      header: "Name",
      cell: (info) => {
        return (
          <Link
            className="text-lightPurple hover:brightness-125"
            to={`${info.row.original.id}`}
          >
            {info.getValue()}
          </Link>
        );
      },
    }),
    columnHelper.accessor("email", {
      header: "Email",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("phone_number", {
      header: "Phone",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("customer_since", {
      header: "Customer Since",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("gender", {
      header: "Gender",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("dob", {
      header: "DOB",
      cell: (info) => info.getValue(),
    }),
  ];
  const table = useReactTable({
    data: customers,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleSubmit = () => {
    if (!userName && !userEmail && !gender) {
      setError("Please enter a something.");
      return;
    }
    setError("");
    const payload: SearchFormQuery = {
      name: userName,
      email: userEmail,
      gender: gender,
    };
    if (isEqual(payload, searchQuery)) {
      refetch();
      return;
    }
    setSearchQuery(payload);
  };

  const handleClearSearch = () => {
    setError("");
    setUserName("");
    setUserEmail("");
    setGender(null);
    setSearchQuery(undefined);
  };

  const genderDisplayMap: GenderDisplayMap = {
    m: "Male",
    f: "Female",
  };

  const handlePageChange = ({ direction }: { direction: "next" | "prev" }) => {
    if (!pagination) return;
    const nextPage =
      direction === "next" ? pagination.next_page : pagination.prev_page;
    setPage(nextPage);
  };
  return (
    <div>
      <div className="mx-auto">
        <div>
          <h1 className="text-2xl font-semibold leading-6 mb-4 flex items-center gap-3">
            <UserGroupIcon className="h-7 w-7" />
            Customers
          </h1>
          <VendorCard className="flex flex-wrap sm:flex-nowrap py-6 items-center gap-4">
            <div className="flex flex-col sm:flex-row sm:items-center relative w-full gap-4">
              <TextInput
                name="name"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                placeholder="Name"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
                className="flex-grow"
              />
              {searchQuery && (
                <button
                  className="text-pink hover:brightness-125 text-xs mt-1 sm:mt-0 sm:absolute sm:left-0 sm:-bottom-4"
                  onClick={handleClearSearch}
                >
                  Clear Search
                </button>
              )}
              <TextInput
                name="email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                placeholder="Email"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
                className="flex-grow"
              />

              <div className="relative flex-grow">
                <Menu as="div" className="relative inline-block text-left w-full">
                  <MenuButton className="flex justify-between items-center w-full focus:outline-purple focus:ring-0 bg-white h-10 px-3 rounded border border-gray-300">
                    <span>{gender ? genderDisplayMap[`${gender}`] : "Gender"}</span>
                    <ChevronDownIcon className="h-5 ml-1" />
                  </MenuButton>
                  <MenuItems className="w-full right-0 absolute bg-white border-gray-300 border z-10 rounded-md">
                    <MenuItem as="div" className="w-full">
                      <button
                        className="block w-full text-left px-3 py-2 hover:bg-gray-200"
                        onClick={() => setGender("m")}
                      >
                        Male
                      </button>
                    </MenuItem>
                    <MenuItem as="div" className="w-full">
                      <button
                        className="block w-full text-left px-3 py-2 hover:bg-gray-200"
                        onClick={() => setGender("f")}
                      >
                        Female
                      </button>
                    </MenuItem>
                  </MenuItems>
                </Menu>
              </div>
              <div className="relative">
                <button
                  className="h-10 bg-purple rounded-lg px-10 text-white w-full sm:w-auto"
                  onClick={handleSubmit}
                >
                  {isFetching ? <Spinner /> : `Search`}
                </button>
              </div>
            </div>
            <span className="text-yellow block text-center text-xs mt-2 sm:absolute sm:-bottom-6 sm:left-0">
              {error}
            </span>
          </VendorCard>


          <VendorCard className="mt-8 flow-root">
            {customers?.length > 0 ? (
              <div className="flex flex-col gap-4">
                {/* Table for Larger Screens */}
                <div className="hidden sm:block">
                  <table className="min-w-full divide-y divide-gray-200 text-sm">
                    <thead>
                      {table.getHeaderGroups().map((headerGroup) => (
                        <tr className="text-gray-500" key={headerGroup.id}>
                          {headerGroup.headers.map((header) => (
                            <th
                              className="text-left px-4 py-2"
                              key={header.id}
                            >
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {table.getRowModel().rows.map((row) => (
                        <tr className="h-11" key={row.id}>
                          {row.getVisibleCells().map((cell) => (
                            <td
                              className="px-4 py-2 text-gray-500"
                              key={cell.id}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* Cards for Mobile */}
                <div className="sm:hidden flex flex-col gap-4">
                  {customers.map((customer: User) => (
                    <div
                      key={customer.id}
                      className="relative p-4 border rounded-md shadow-sm"
                    >
                      <h3 className="font-bold text-gray-700">
                        <Link
                          to={`${customer.id}`}
                          className="text-lightPurple hover:brightness-125"
                        >
                          {customer.full_name}
                        </Link>
                      </h3>
                      <p className="text-sm text-gray-500 mt-1">
                        <b>Email:</b> {customer.email || "N/A"}
                      </p>
                      <p className="text-sm text-gray-500">
                        <b>Phone:</b> {customer.phone_number || "N/A"}
                      </p>
                      <p className="text-sm text-gray-500">
                        <b>Customer Since:</b> {customer.customer_since || "N/A"}
                      </p>
                      <p className="text-sm text-gray-500">
                        <b>Gender:</b> {customer.gender || "N/A"}
                      </p>
                      <p className="text-sm text-gray-500">
                        <b>DOB:</b> {customer.dob || "N/A"}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="flex justify-center items-center py-6">
                <span className="font-medium text-gray-600">No customers yet</span>
              </div>
            )}

            {pagination && (
              <div className="flex justify-center items-center gap-2 my-4">
                {pagination?.prev_page && (
                  <ChevronDoubleLeftIcon
                    className="h-5 text-gray-400 cursor-pointer"
                    onClick={() => handlePageChange({ direction: "prev" })}
                  />
                )}
                {pagination?.total_pages > 1 && (
                  <span className="text-sm">{pagination?.current_page}</span>
                )}
                {pagination?.next_page && (
                  <ChevronDoubleRightIcon
                    onClick={() => handlePageChange({ direction: "next" })}
                    className="h-5 text-gray-400 cursor-pointer"
                  />
                )}
              </div>
            )}
          </VendorCard>



        </div>
      </div>
    </div>
  );
};
