import { useQuery } from "@tanstack/react-query";
import { useVendor } from "../../../providers/VendorContext";
import { makeApiRequest } from "../../../utils/api";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { Fragment, useState } from "react";
import { format } from "date-fns";
import { Payout, PayoutStatus } from "../../../types/Payout";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { formatCentsToDollars } from "../../../utils/currency";
import { Menu, Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  BanknotesIcon,
  Cog6ToothIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import {
  loadConnectAndInitialize,
  StripeConnectInstance,
} from "@stripe/connect-js";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { Button } from "../../../components/reusable/Button";
import { PayoutAccount } from "../../../types/PayoutAccount";
import { is } from "date-fns/locale";
import { Spinner } from "../../../components/reusable/Spinner";
import { HappyTooltip } from "../../../components/reusable/Tooltip";
import { useNotification } from "../../../providers/NotificationContext";
import { sum } from "lodash";
import { Modal } from "../../../components/reusable/Modal";
import { classNames } from "../../../utils/styles";
import { Withdrawal } from "../../../types/Withdrawal";

type SearchFormQuery = {
  paymentId: string;
  eventName: string;
  userName: string;
  dateFrom: Date | null;
  dateTo: Date | null;
};

export const PayoutList = () => {
  const { showError, showSuccess } = useNotification();
  const [showWithdrawalModal, setShowWithdrawalModal] = useState(false);
  const { currentOrganization } = useVendor();
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [activeTab, setActiveTab] = useState<'payouts' | 'withdrawals'>('payouts');

  const [searchQuery, setSearchQuery] = useState<SearchFormQuery | null>(null);
  const [stripeConnectSession, setStripeConnectSession] =
    useState<StripeConnectInstance | null>(null);

  const { data: payoutData, refetch: refetchPayouts } = useQuery({
    queryKey: ["payouts", currentOrganization, searchQuery],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/organizations/${currentOrganization?.id}/payouts`,
        params: {
          search: searchQuery,
        },
      }),
    enabled: !!currentOrganization && activeTab === 'payouts',
    refetchInterval: 10000,
  });

  const payouts = payoutData?.data.payouts;

  const { data: withdrawalData } = useQuery({
    queryKey: ["withdrawals", currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/organizations/${currentOrganization?.id}/withdrawals`,
      }),
    enabled: !!currentOrganization,
  });

  if (showOnboarding) {
    return (
      <>
        <div className="flex justify-start mb-5">
          <Button
            variant="link"
            onClick={() => {
              setShowOnboarding(false);
              setStripeConnectSession(null);
            }}
          >
            <ArrowLeftIcon className="h-5 w-5" />
            Go Back
          </Button>
        </div>
        {stripeConnectSession && (
          <VendorCard className="min-h-[70vh] flex items-center justify-center py-6">
            <ConnectComponentsProvider connectInstance={stripeConnectSession}>
              <ConnectAccountOnboarding
                onExit={() => {
                  setShowOnboarding(false);
                }}
              />
            </ConnectComponentsProvider>
          </VendorCard>
        )}
      </>
    );
  }
  return (
    <>
      <div className="flex justify-between">
        <h1 className="text-2xl font-semibold leading-6 text-gray-500 flex items-center gap-3">
          <BanknotesIcon className="h-7 w-7" />
          Payouts
        </h1>
      </div>

      <BankingHeader
        setShowWithdrawalModal={setShowWithdrawalModal}
        setShowOnboarding={setShowOnboarding}
        setStripeConnectSession={setStripeConnectSession}
      />

      <VendorCard className="mt-6">
        <div className="flex justify-between items-center mb-6">
          <div className="flex gap-4">
            <button
              className={`px-4 py-2 rounded-lg ${activeTab === 'payouts'
                ? 'bg-blue text-white'
                : 'bg-gray-100 text-gray-600'
                }`}
              onClick={() => setActiveTab('payouts')}
            >
              Payouts
            </button>
            <button
              className={`px-4 py-2 rounded-lg ${activeTab === 'withdrawals'
                ? 'bg-blue text-white'
                : 'bg-gray-100 text-gray-600'
                }`}
              onClick={() => setActiveTab('withdrawals')}
            >
              Withdrawals
            </button>
          </div>

          <div className="flex flex-col items-end">
            <div className="text-sm text-gray-500 mb-1">
              {activeTab === 'payouts' ? 'Total Payouts' : 'Total Withdrawals'}
            </div>
            <div className="text-3xl font-bold text-green-500">
              {activeTab === 'payouts' ? (
                formatCentsToDollars(payouts?.reduce((sum: number, p: Payout) => sum + p.amount_cents, 0) || 0)
              ) : (
                formatCentsToDollars(withdrawalData?.data?.withdrawals?.reduce((sum: number, w: Withdrawal) => sum + w.amount_cents, 0) || 0)
              )}
            </div>
          </div>
        </div>

        {activeTab === 'payouts' ? (
          payouts && payouts.length ? (
            <PayoutTable payouts={payouts} />
          ) : (
            <div className="flex justify-center items-center h-30">
              <span className="font-medium text-gray-600">No payouts yet</span>
            </div>
          )
        ) : (
          <WithdrawalList />
        )}
      </VendorCard>

      <WithdrawalModal
        isOpen={showWithdrawalModal}
        onRequestClose={() => {
          setShowWithdrawalModal(false)
          refetchPayouts()
        }}
      />
    </>
  );
};

export const PayoutTable = ({ payouts }: { payouts: Payout[] }) => {
  const columnHelper = createColumnHelper<Payout>();
  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => info.getValue(),
    }),

    columnHelper.accessor("amount_cents", {
      header: "Payout Amount",
      cell: (info) => {
        const amount = info.getValue() as number;
        return formatCentsToDollars(amount);
      },
    }),
    columnHelper.accessor("payout_date", {
      header: "Date",
      cell: (info) => {
        const date = info.getValue() as string;
        return format(new Date(date), "MM/dd/yyyy");
      },
    }),
    columnHelper.accessor("payout_method", {
      header: "Method",
      cell: (info) => info.getValue(),
    }),
    // columnHelper.accessor("status", {
    //   header: "Status",
    //   cell: (info) => {
    //     const status: PayoutStatus = info.getValue()
    //     const classes = "px-3 py-1 rounded-full text-xs font-semibold capitalize"
    //     let color = "bg-blue/20 text-blue"
    //     if (status === "paid") {
    //       color = "bg-green-500/20 text-green-500"
    //     } else if (status === "failed" || status === "canceled") {
    //       color = "bg-red-500/20 text-red-500"
    //     } else if (status === "pending") {
    //       color = "bg-blue/20 text-blue"
    //     }
    //     return (
    //       <span className={classNames(
    //         classes,
    //         color
    //       )}>
    //         {status}
    //       </span>
    //     )
    //   },
    // }),
    // columnHelper.display({
    //   id: "actions",
    //   cell: (props) => {
    //     return (
    //       <div className="flex">
    //         <Menu as="div" className={"relative inline-block"}>
    //           <Menu.Button className="bg-transparent flex items-center justify-center">
    //             <Cog6ToothIcon className="h-6 text-white/30" />
    //           </Menu.Button>
    //           <Transition
    //             as={Fragment}
    //             enter="transition ease-out duration-100"
    //             enterFrom="transform opacity-0 scale-95"
    //             enterTo="transform opacity-100 scale-100"
    //             leave="transition ease-in duration-75"
    //             leaveFrom="transform opacity-100 scale-100"
    //             leaveTo="transform opacity-0 scale-95"
    //           >
    //             <Menu.Items className="w-40 right-0 absolute bg-dropdown border-borderAdmin border z-10 rounded-md">
    //               <Menu.Item
    //                 as={"div"}
    //                 className={"px-3 py-2 hover:bg-white/10"}
    //               >
    //                 <Link
    //                   className="text-white/60 block"
    //                   to={`/admin/organizations/${props.row.original.id}`}
    //                 >
    //                   Edit
    //                 </Link>
    //               </Menu.Item>
    //               <Menu.Item
    //                 as={"div"}
    //                 className={
    //                   "px-3 py-2 hover:bg-white/10 border-borderAdmin border-t"
    //                 }
    //               >
    //                 <Link
    //                   className="text-pink/60 block"
    //                   to={`/admin/organizations/${props.row.original.id}`}
    //                 >
    //                   Delete
    //                 </Link>
    //               </Menu.Item>
    //             </Menu.Items>
    //           </Transition>
    //         </Menu>
    //       </div>
    //     );
    //   },
    // }),
  ];

  const table = useReactTable({
    data: payouts,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <>
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className="" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th className="text-left" key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="divide-y divide-gray-300">
          {table?.getRowModel()?.rows?.map((row) => {
            return (
              <tr className="h-11" key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td className="py-1" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export const BankingHeader = ({
  setStripeConnectSession,
  setShowOnboarding,
  setShowWithdrawalModal,
}: {
  setStripeConnectSession: (stripe_instance: StripeConnectInstance) => void;
  setShowOnboarding: (show: boolean) => void;
  setShowWithdrawalModal: (show: boolean) => void;
}) => {
  const { currentOrganization, refetchOrganization } = useVendor();

  const { data: balanceData } = useQuery({
    queryKey: ["account_balance", currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/organizations/${currentOrganization?.id}/account_balance`,
        method: "GET",
      }),
    enabled: !!currentOrganization,
    refetchInterval: 10000,
  });

  const {
    data: accountData,
    refetch: refetchAccounts,
    isFetching: fetchingAccounts,
  } = useQuery({
    queryKey: ["accounts", currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/bank_accounts`,
        params: {
          organization_id: currentOrganization?.id,
        },
      }),
    enabled: !!currentOrganization,
  });
  const balance: Balance = balanceData?.data.balance;
  const accounts = accountData?.data;
  const activeAccount = accounts?.find(
    (account: PayoutAccount) => account.active
  );
  const connectedAccount = currentOrganization?.connected_account;
  const totalAvailableBalance = sum(balance?.available.map((b) => b.amount)) +
    (balance?.pending ? Math.min(0, sum(balance.pending.map((b) => b.amount))) : 0);
  const fetchConnectSession = async (stripeAccountID?: string) => {
    const response = await makeApiRequest({
      path: "/vendor/bank_accounts",
      method: "POST",
      params: {
        organization_id: currentOrganization?.id,
        stripe_account_id: stripeAccountID,
      },
    });

    if (response.status === 201) {
      console.log(response);
      const { stripe_connect_session } = response.data;
      setStripeConnectSession(
        loadConnectAndInitialize({
          publishableKey: import.meta.env.VITE_STRIPE_KEY,
          fetchClientSecret: () => stripe_connect_session.client_secret,
          appearance: {
            overlays: "dialog",
            variables: {
              colorPrimary: "#635BFF",
            },
          },
        })
      );
      setShowOnboarding(true);
      refetchOrganization();
    }
  };

  return (
    <div>
      {connectedAccount ? (
        <VendorCard className="mt-6 w-fit bg-blue sticky">
          <div className="flex justify-start gap-10">
            <div className="flex flex-col items-center justify-center gap-3">
              <span className="text-sm font-semibold flex gap-1">
                Stripe Account Balance

                <HappyTooltip anchor="top" content="The funds Happy Ticketing has sent to your Stripe account and are available to be withdrawn to your bank account.">
                  <QuestionMarkCircleIcon className="h-5 w-5 text-gray-500" />
                </HappyTooltip>
              </span>
              <span className="text-3xl font-bold text-green-500">
                {formatCentsToDollars(totalAvailableBalance)}
              </span>
              {totalAvailableBalance > 0 && activeAccount && (
                <Button onClick={() => setShowWithdrawalModal(true)} variant="blue">
                  <BanknotesIcon className="h-5 w-5" />
                  Withdraw Funds
                </Button>
              )}
            </div>

            <div className="flex flex-col justify-between items-center">
              <span className="text-sm font-semibold flex gap-1">
                Payout Account
                <HappyTooltip anchor="top" content="Funds that are withdrawn will go to this bank account.">
                  <QuestionMarkCircleIcon className="h-5 w-5 text-gray-500" />
                </HappyTooltip>
              </span>
              {activeAccount ? (
                <div className="rounded-lg border border-gray-300 bg-blue/10 shadow-sm p-4 flex flex-col items-center">
                  <span className="text-blue font-semibold">
                    {activeAccount.bank_name}
                  </span>
                  <span className="text-sm text-gray-500">
                    Account ending in {activeAccount.bank_last4}
                  </span>
                </div>
              ) : (
                <Button
                  onClick={() =>
                    fetchConnectSession(connectedAccount.stripe_account_id)
                  }
                >
                  <Cog6ToothIcon className="h-5 w-5" />
                  Finish Setup
                </Button>
              )}
            </div>
          </div>
        </VendorCard>
      ) : (
        <div className="p-3 flex items-center justify-center bg-blue/10 font-semibold text-blue border border-blue/10 rounded-lg mt-5">
          <div className="flex items-center justify-center flex-col gap-5">
            <span className="text-lg font-semibold">
              It looks like you haven't connected a bank account yet. Let's fix
              that.
            </span>

            <Button
              className="flex gap-3 items-center"
              variant="blue"
              onClick={() => fetchConnectSession()}
            >
              <BanknotesIcon className="h-5 w-5" />
              Connect Bank Account
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export const WithdrawalModal = ({
  isOpen,
  onRequestClose,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
}) => {
  const { currentOrganization } = useVendor();
  const [amount, setAmount] = useState<string>('0');
  const [loading, setLoading] = useState(false);
  const { showError, showSuccess } = useNotification();
  const { refetch: refetchWithdrawals } = useQuery({
    queryKey: ["withdrawals", currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/organizations/${currentOrganization?.id}/withdrawals`,
      }),
    enabled: !!currentOrganization,
  });
  const { data: balanceData } = useQuery({
    queryKey: ["account_balance", currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/organizations/${currentOrganization?.id}/account_balance`,
        method: "GET",
      }),
    enabled: !!currentOrganization,
  });

  const balance: Balance = balanceData?.data.balance;
  const totalAvailableBalance = sum(balance?.available.map((b) => b.amount)) +
    (balance?.pending ? Math.min(0, sum(balance.pending.map((b) => b.amount))) : 0);

  const handleFormSubmit = async () => {
    setLoading(true);
    const response = await makeApiRequest({
      path: `/vendor/organizations/${currentOrganization?.id}/withdrawals`,
      method: "POST",
      params: {
        organization_id: currentOrganization?.id,
        withdrawal: {
          organization_id: currentOrganization?.id,
          amount_cents: parseFloat(amount) * 100,
        },
      },
    });
    setLoading(false);
    if (response.status === 201) {
      showSuccess("Withdrawal request submitted successfully");
      refetchWithdrawals();
      onRequestClose();
    } else {
      showError("An error occurred. Please try again later");
    }
  };
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className="flex flex-col gap-5">
        <span className="text-lg font-semibold">
          Withdraw Funds (Max: {formatCentsToDollars(totalAvailableBalance)})
          <br />
          <span className="text-sm text-gray-400">Note: Stripe charges 0.25% + $0.25 for each withdrawal. This will automatically be deducted from your withdrawal amount.</span>
        </span>

        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-3">
            <span className="text-sm font-semibold">Amount</span>
            <input
              onChange={(e) => {
                let value = e.target.value;

                // Remove non-numeric characters except decimal point
                value = value.replace(/[^\d.]/g, '');

                // Prevent multiple leading zeros (e.g., "0005" -> "5")
                value = value.replace(/^0+(\d)/, '$1');

                // Ensure only one decimal point
                value = value.replace(/(\..*)\./g, '$1');

                // Limit to 2 decimal places
                let truncated = value.match(/^\d*\.?\d{0,2}/)?.[0] || '';

                // Convert to a number
                let parsed = parseFloat(truncated) || 0;

                // Enforce max limit dynamically
                if (parsed > totalAvailableBalance / 100) {
                  parsed = totalAvailableBalance / 100;
                  truncated = parsed.toFixed(2); // Ensure two decimal places
                }

                setAmount(truncated); // Use string to keep input state accurate
              }}
              value={amount} // Bind value to prevent excess input
              name="amount"
              type="text"  // Switch to "text" to prevent browser auto-formatting numbers
              inputMode="decimal" // Ensures numeric keyboard on mobile
              step="0.01"
              min="0"
              max={totalAvailableBalance / 100}
              autoComplete="off"
              className="w-full p-3 border border-gray-300 rounded-lg"
              placeholder="Enter Amount"
            />


          </div>
        </div>
        <Button size="lg" variant="success" onClick={handleFormSubmit} type="submit">
          {loading ? <Spinner /> : "Withdraw Funds"}
        </Button>
      </div>
    </Modal>
  );
};

export const WithdrawalList = () => {
  const { currentOrganization } = useVendor();
  const columnHelper = createColumnHelper<any>();

  const { data: withdrawalData, refetch: refetchWithdrawals } = useQuery({
    queryKey: ["withdrawals", currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/organizations/${currentOrganization?.id}/withdrawals`,
      }),
    enabled: !!currentOrganization,
  });

  const withdrawals = withdrawalData?.data?.withdrawals || [];

  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("amount_cents", {
      header: "Amount",
      cell: (info) => formatCentsToDollars(info.getValue()),
    }),
    columnHelper.accessor("created_at", {
      header: "Date",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("bank_account_name", {
      header: "Bank Account",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("user_name", {
      header: "Initiated By",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: (info) => {
        const status = info.getValue();
        const classes = "px-3 py-1 rounded-full text-xs font-semibold capitalize";
        let color = "bg-blue/20 text-blue";
        if (status === "completed") {
          color = "bg-green-500/20 text-green-500";
        } else if (status === "failed") {
          color = "bg-red-500/20 text-red-500";
        } else if (status === "paid") {
          color = "bg-green-500/20 text-green-500";
        } else if (status === "pending") {
          color = "bg-blue/20 text-blue";
        }
        return (
          <span className={classNames(classes, color)}>
            {status}
          </span>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: withdrawals,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th className="text-left" key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="divide-y divide-gray-300">
          {table.getRowModel().rows.map((row) => (
            <tr className="h-11" key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td className="py-1" key={cell.id}>
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {withdrawals.length === 0 && (
        <div className="flex justify-center items-center h-30">
          <span className="font-medium text-gray-600">No withdrawals yet</span>
        </div>
      )}
    </>
  );
};
