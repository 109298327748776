import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../../utils/api";
import { Link, useNavigate } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import DatePicker from "react-datepicker";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import { Fragment, useEffect, useState } from "react";
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { HappyEvent } from "../../../types/Event";
import { isEqual } from "lodash";
import { useVendor } from "../../../providers/VendorContext";
import { Modal } from "../../../components/reusable/Modal";
import { Button } from "../../../components/reusable/Button";
import { useNotification } from "../../../providers/NotificationContext";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { EventTemplateSelect } from "../EventQuickStart/EventTemplateSelect";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { isAfter, isBefore } from "date-fns";
import { TextInput } from "../../../components/reusable/Form";
type SearchFormQuery = {
  id: string;
  name: string;
  from: Date | null;
  to: Date | null;
};

export const EventList = () => {
  const { currentOrganization } = useVendor();
  const [visibleEvents, setVisibleEvents] = useState<HappyEvent[]>([]);
  const [filter, setFilter] = useState<"past" | "current" | null>("current");
  const [eventName, setEventName] = useState<string>("");
  const [eventId, setEventId] = useState<string>("");
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [copyEvent, setCopyEvent] = useState<HappyEvent | null>(null);
  const [showTemplateModal, setShowTemplateModal] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<SearchFormQuery>();
  const { showSuccess } = useNotification();

  const handleDeleteEvent = (eventId: number) => {
    makeApiRequest({
      path: `/vendor/events/${eventId}`,
      method: "DELETE",
      params: { organization_id: currentOrganization?.id },
    }).then((res) => {
      if (res.status === 200) {
        refetch();
        showSuccess("Event deleted successfully");
        return;
      }
    });
  };

  const columnHelper = createColumnHelper<HappyEvent>();
  const { data, refetch } = useQuery({
    queryKey: ["events", searchQuery, currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: "/vendor/events",
        params: {
          organization_id: currentOrganization?.id,
          search: { name: eventName, id: eventId, to: dateTo, from: dateFrom },
        },
      }),
    enabled: !!currentOrganization,
    refetchOnWindowFocus: true,
  });
  const events = data?.data;

  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("name", {
      header: "Name",
      cell: (info) => (
        <Link
          className="text-lightPurple hover:brightness-125"
          to={`${info.row.original.id}`}
        >
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor("section_count", {
      header: "Sections",
      cell: (info) => (
        <Link
          className="text-lightPurple hover:brightness-125"
          to={`${info.row.original.id}/sections`}
        >
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor("ticket_count", {
      header: "Tickets",
      cell: (info) => (
        <Link
          className="text-lightPurple hover:brightness-125"
          to={`${info.row.original.id}/tickets`}
        >
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor("order_count", {
      header: "Orders",
      cell: (info) => (
        <Link
          className="text-lightPurple hover:brightness-125"
          to={`${info.row.original.id}/orders`}
        >
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor("start", {
      header: "Start",
      cell: (info) => info.getValue() as string,
    }),
    columnHelper.accessor("end", {
      header: "End",
      cell: (info) => info.getValue() as string,
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => (
        <div className="flex">
          <Menu as="div" className="relative inline-block text-left">
            <MenuButton className="bg-transparent flex items-center justify-center">
              <Cog6ToothIcon className="h-6 text-gray-300" />
            </MenuButton>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="w-40 right-0 absolute bg-white shadow-sm border z-10 rounded-md">
                <MenuItem as="div" className="px-3 py-2 hover:bg-gray-50">
                  <button
                    onClick={() => setCopyEvent(props.row.original)}
                    className="text-gray-500 w-full text-left"
                  >
                    Copy Event
                  </button>
                </MenuItem>
                <MenuItem as="div" className="px-3 py-2 hover:bg-gray-50">
                  <Link
                    className="text-gray-500 block"
                    to={`/vendor/organizations/${currentOrganization?.id}/events/${props.row.original.id}/reports/sales-by-tier`}
                  >
                    View Reports
                  </Link>
                </MenuItem>
                <MenuItem
                  as="div"
                  className="px-3 py-2 hover:bg-gray-50 border-gray-300 border-t"
                >
                  <button
                    onClick={() => {
                      if (
                        window.confirm("Are you sure you want to delete this event?")
                      ) {
                        handleDeleteEvent(props.row.original.id);
                      }
                    }}
                    className="text-pink/60 block cursor-pointer"
                  >
                    Delete
                  </button>
                </MenuItem>
              </MenuItems>
            </Transition>
          </Menu>
        </div>
      ),
    }),
  ];

  const table = useReactTable({
    data: visibleEvents,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    const payload: SearchFormQuery = {
      id: eventId,
      name: eventName,
      to: dateTo,
      from: dateFrom,
    };
    if (isEqual(payload, searchQuery)) {
      refetch();
      return;
    }
    setSearchQuery(payload);
  };

  const handleClearSearch = () => {
    setError("");
    setEventId("");
    setEventName("");
    setDateFrom(null);
    setDateTo(null);
    setSearchQuery(undefined);
  };

  useEffect(() => {
    if (!events) return;
    if (!filter) return setVisibleEvents(events);

    let filteredEvents = events;
    if (filter === "current") {
      filteredEvents = events.filter(
        (event: HappyEvent) => new Date(event.end_date) >= new Date()
      );
    } else {
      filteredEvents = events.filter(
        (event: HappyEvent) => new Date(event.end_date) < new Date()
      );
    }

    setVisibleEvents(filteredEvents);
  }, [filter, events]);

  return (
    <>
      <div>
        <div className="mx-auto mb-36">
          <div className="">
            <div>
              <div className="flex items-center justify-between">
                <h1 className="font-semibold leading-6 text-gray-500 text-2xl flex items-center gap-3">
                  <CalendarIcon className="w-7 h-7" /> Events
                </h1>
                <Link to="builder">
                  <Button variant="blue" className="flex items-center">
                    <PlusIcon className="h-5 mr-1" />
                    Add Event
                  </Button>
                </Link>
              </div>

              <div className="mt-4 px-4 py-6 rounded-lg bg-white shadow-sm">
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-5 items-center">
                    <TextInput
                      name="eventId"
                      value={eventId}
                      onChange={(e) => setEventId(e.target.value)}
                      placeholder="Event ID"
                      className="w-full"
                    />
                    <TextInput
                      name="eventName"
                      value={eventName}
                      onChange={(e) => setEventName(e.target.value)}
                      placeholder="Event Name"
                      className="w-full"
                    />
                    <div className="relative">
                      <label className="text-xs absolute -top-4 text-gray-500">
                        From
                      </label>
                      <DatePicker
                        placeholderText="Start Date"
                        wrapperClassName="w-full"
                        selected={dateFrom}
                        onChange={(date) => setDateFrom(date as Date)}
                        className="h-10 w-full rounded bg-white text-gray-500 focus:ring-purple focus:border-purple border border-gray-300 shadow-sm placeholder:text-gray-400"
                      />
                    </div>
                    <div className="relative">
                      <label className="text-xs absolute -top-4 text-gray-500">
                        To
                      </label>
                      <DatePicker
                        placeholderText="End Date"
                        wrapperClassName="w-full"
                        selected={dateTo}
                        onChange={(date) => setDateTo(date as Date)}
                        className="h-10 w-full rounded bg-white text-gray-500 focus:ring-purple focus:border-purple border border-gray-300 shadow-sm placeholder:text-gray-400"
                      />
                    </div>
                    <div className="flex items-center gap-2">
                      <button
                        type="submit"
                        className="h-10 w-full lg:w-auto bg-purple rounded-lg px-6 text-white"
                      >
                        Search
                      </button>
                      {searchQuery && (
                        <button
                          type="button"
                          className="text-pink hover:brightness-125 text-sm"
                          onClick={handleClearSearch}
                        >
                          Clear
                        </button>
                      )}
                    </div>
                  </div>
                  {error && (
                    <span className="text-yellow text-xs block mt-2">
                      {error}
                    </span>
                  )}
                </form>
              </div>

              <VendorCard className="mt-8">
                <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-3">
                  <span className="block my-4 text-sm text-gray-400">
                    Displaying {visibleEvents.length} events
                  </span>
                  <div className="flex items-center gap-3 flex-wrap">
                    <button
                      onClick={() => setFilter("current")}
                      className={`rounded-full w-24 py-1 text-sm font-bold ${filter === "current"
                          ? "bg-purple/10 text-purple"
                          : "bg-gray-100 text-gray-400"
                        }`}
                    >
                      Current
                    </button>
                    <button
                      onClick={() => setFilter("past")}
                      className={`rounded-full w-24 py-1 text-sm font-bold ${filter === "past"
                          ? "bg-purple/10 text-purple"
                          : "bg-gray-100 text-gray-400"
                        }`}
                    >
                      Past
                    </button>
                    <button
                      onClick={() => setFilter(null)}
                      className={`rounded-full w-24 py-1 text-sm font-bold ${!filter
                          ? "bg-purple/10 text-purple"
                          : "bg-gray-100 text-gray-400"
                        }`}
                    >
                      All
                    </button>
                  </div>
                </div>

                <div>
                  {visibleEvents.length > 0 ? (
                    <>
                      {/* Table for larger screens */}
                      <div className="hidden sm:block">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                              <tr
                                className="text-gray-500 text-sm"
                                key={headerGroup.id}
                              >
                                {headerGroup.headers.map((header) => (
                                  <th
                                    className="text-left px-4 py-2"
                                    key={header.id}
                                  >
                                    {header.isPlaceholder
                                      ? null
                                      : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                  </th>
                                ))}
                              </tr>
                            ))}
                          </thead>
                          <tbody className="divide-y divide-gray-200">
                            {table.getRowModel().rows.map((row) => (
                              <tr className="h-11 text-sm" key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                  <td
                                    className="py-2 px-4 text-gray-400"
                                    key={cell.id}
                                  >
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      {/* Card layout for small screens */}
                      <div className="block sm:hidden space-y-4">
                        {visibleEvents.map((event) => (
                          <div
                            key={event.id}
                            className="p-3 border rounded-lg shadow-sm space-y-2"
                          >
                            <div className="flex justify-between">

                              <div className="text-sm text-gray-400">Event #{event.id}</div>
                              <Menu as="div" className="relative">
                                <div className="flex justify-end">
                                  <MenuButton className="p-1 border rounded bg-white">
                                    <Cog6ToothIcon className="h-4 w-4 text-gray-400" />
                                  </MenuButton>
                                </div>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <MenuItems className="absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
                                    <MenuItem as="div" className="px-4 py-2 hover:bg-gray-100">
                                      <button
                                        onClick={() => setCopyEvent(event)}
                                        className="text-gray-700 w-full text-left"
                                      >
                                        Copy Event
                                      </button>
                                    </MenuItem>
                                    <MenuItem as="div" className="px-4 py-2 hover:bg-gray-100">
                                      <Link
                                        to={`/vendor/organizations/${currentOrganization?.id}/events/${event.id}/reports/sales-by-tier`}
                                        className="text-gray-700 block"
                                      >
                                        View Reports
                                      </Link>
                                    </MenuItem>
                                    <MenuItem
                                      as="div"
                                      className="px-4 py-2 hover:bg-gray-100 border-t border-gray-200"
                                    >
                                      <button
                                        onClick={() => {
                                          if (
                                            window.confirm("Are you sure you want to delete this event?")
                                          ) {
                                            handleDeleteEvent(event.id);
                                          }
                                        }}
                                        className="text-pink w-full text-left"
                                      >
                                        Delete Event
                                      </button>
                                    </MenuItem>
                                  </MenuItems>
                                </Transition>
                              </Menu>
                            </div>
                            <div className="text-xl font-bold text-lightPurple text-center">
                              <Link
                                to={`${event.id}`}
                                className="hover:brightness-125"
                              >
                                {event.name}
                              </Link>
                            </div>

                            <div className="text-sm text-gray-500 text-center">
                              <div>{event.start_date} - {event.end_date}</div>
                            </div>

                            <div className="space-y-2">

                              <div className="flex justify-between">
                                <span className="font-medium text-gray-500">Sections:</span>
                                <Link
                                  to={`${event.id}/sections`}
                                  className="text-lightPurple hover:brightness-125"
                                >
                                  {event.section_count}
                                </Link>
                              </div>
                              <div className="flex justify-between">
                                <span className="font-medium text-gray-500">Tickets:</span>
                                <Link
                                  to={`${event.id}/tickets`}
                                  className="text-lightPurple hover:brightness-125"
                                >
                                  {event.ticket_count}
                                </Link>
                              </div>
                              <div className="flex justify-between">
                                <span className="font-medium text-gray-500">Orders:</span>
                                <Link
                                  to={`${event.id}/orders`}
                                  className="text-lightPurple hover:brightness-125"
                                >
                                  {event.order_count}
                                </Link>
                              </div>
                            </div>


                          </div>

                        ))}
                      </div>
                    </>
                  ) : (
                    <span className="text-center block font-medium text-gray-600 mt-4">
                      There are currently no events matching your search
                    </span>
                  )}
                </div>
              </VendorCard>
            </div>
          </div>
        </div>
      </div>
      <CopyEventModal
        key={"copy-event-modal"}
        event={copyEvent}
        onRequestClose={() => setCopyEvent(null)}
      />
      <EventTemplateSelect
        onRequestClose={() => setShowTemplateModal(false)}
        isOpen={showTemplateModal}
        key={"event-template-select-modal"}
      />
    </>
  );
};

const CopyEventModal = ({
  event,
  onRequestClose,
}: {
  event: HappyEvent | null;
  onRequestClose: () => void;
}) => {
  const navigate = useNavigate();
  const { currentOrganization } = useVendor();
  const { showError, showSuccess } = useNotification();
  const [name, setName] = useState<string>("");
  const [eventStart, setEventStart] = useState<Date | null>(null);
  const [eventEnd, setEventEnd] = useState<Date | null>(null);
  const copyEvent = () => {
    makeApiRequest({
      path: "/vendor/copy_event",
      method: "POST",
      params: {
        organization_id: currentOrganization?.id,
        event_id: event?.id,
        event_options: {
          name,
          start_date: eventStart,
          end_date: eventEnd,
        },
      },
    })
      .then((res) => {
        if (res.status === 201) {
          const event = res.data;
          onRequestClose();
          showSuccess("Event copied successfully");
          navigate(
            `/vendor/organizations/${currentOrganization?.id}/events/${event.id}`
          );
          return;
        }
      })
      .catch((err) => {
        showError(err.response?.data?.message || "An error occurred");
      });
  };
  return (
    <Modal
      title={`Copy ${event?.name}`}
      isOpen={!!event}
      onRequestClose={onRequestClose}
    >
      <div>
        <p className="my-6 text-white block bg-lightPurple p-3 rounded-lg text-center m-t-3">
          You are copying the {event?.name} event. This will create a new event
          with the same sections, tiers, and price levels. If you wish to change
          them, you can do so after copying the event.
        </p>

        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-1">
            <span className="font-bold text-gray-500">New Event Name</span>
            <input
              className="border border-gray-300 rounded-lg w-full p-4 h-10 placeholder:text-gray-400"
              placeholder="New Event Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
            />
          </div>

          <div className="w-full">
            <span className="font-bold text-gray-500 my-2">Starts</span>
            <div className="w-full flex items-center">
              <DatePicker
                wrapperClassName="w-full"
                dateFormat={"E, MMM do @ h:mm a"}
                selected={eventStart ? new Date(eventStart) : null}
                placeholderText="Start Date and Time"
                className="border border-gray-300 rounded-lg w-full p-4 h-10 placeholder:text-gray-400"
                timeIntervals={15}
                timeCaption="time"
                showTimeSelect
                onChange={(e) => setEventStart(e)}
              />
            </div>
          </div>

          <div className="w-full">
            <span className="font-bold text-gray-500 my-2">Ends</span>
            <div className="w-full flex items-center">
              <DatePicker
                wrapperClassName="w-full"
                selected={eventEnd ? new Date(eventEnd) : null}
                dateFormat={"E, MMM do @ h:mm a"}
                placeholderText="End Date and Time"
                className="border border-gray-300 rounded-lg w-full p-4 h-10 placeholder:text-gray-400"
                timeIntervals={15}
                timeCaption="time"
                showTimeSelect
                onChange={(e) => setEventEnd(e)}
              />
            </div>
          </div>
        </div>

        <div className="w-full flex justify-end my-5">
          <Button
            onClick={copyEvent}
            className="ml-auto"
            variant="success"
            size="lg"
          >
            Copy Event
          </Button>
        </div>
      </div>
    </Modal>
  );
};
