import { useState } from "react";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { HappyEvent } from "../../../types/Event";
import { EventMessage } from "../../../types/EventMessage";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import { Modal } from "../../../components/reusable/Modal";
import RichTextEditor from "../../../components/RichTextEditor";
import { Button } from "../../../components/reusable/Button";
import { PaperAirplaneIcon, PlusIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { makeApiRequest } from "../../../utils/api";
import { useAuth } from "../../../providers/AuthContext";
import { useVendor } from "../../../providers/VendorContext";
import { useNotification } from "../../../providers/NotificationContext";
import { useQuery } from "@tanstack/react-query";
import { VendorTable } from "../../../components/reusable/VendorTable";
import { Resources } from "../../../types/Resources";
import { sanitizeHTML } from "../../../utils/sanitizeHTML";


export const EventMessages = ({ event }: { event: HappyEvent }) => {
  const { currentOrganization } = useVendor();
  const { user } = useAuth();
  const { showError, showSuccess } = useNotification();
  const [showingModal, setShowingModal] = useState(false);
  const [modalContent, setModalContent] = useState<"new" | EventMessage>();
  const [messageContent, setMessageContent] = useState<string>("");
  const [messageSubject, setMessageSubject] = useState<string>("");

  // Tier, Section, Price Level state
  const [selectedTier, setSelectedTier] = useState<string | null>(null);
  const [selectedSection, setSelectedSection] = useState<string | null>(null);
  const [selectedPriceLevel, setSelectedPriceLevel] = useState<string | null>(null);

  // Extract tiers, sections, and price levels from event object
  const tiers = event.tiers || [];
  const sections = event.sections || [];
  const priceLevels = event.price_levels || [];

  // Filter sections based on selected tier
  const filteredSections = selectedTier
    ? sections.filter((section) => section.tier_id === Number(selectedTier))
    : sections;

  // Filter price levels based on selected section
  const filteredPriceLevels = selectedSection
    ? priceLevels.filter((level) => level.section_id === Number(selectedSection))
    : priceLevels;

  const showNewMessageModal = () => {
    setModalContent("new");
    setSelectedTier(null);
    setSelectedSection(null);
    setSelectedPriceLevel(null);
    setShowingModal(true);
  };

  const sendMessage = () => {
    if (!messageSubject) {
      showError("Subject is required");
      return;
    }

    const additionalOptions: Record<string, string> = {};

    if (selectedTier) additionalOptions["tier_id"] = selectedTier;
    if (selectedSection) additionalOptions["section_id"] = selectedSection;
    if (selectedPriceLevel) additionalOptions["price_level_id"] = selectedPriceLevel;

    const params: {
      organization_id?: number;
      message: {
        subject: string;
        body: string;
        event_id: number;
        user_id?: number;
        additional_options?: Record<string, string>;
      };
    } = {
      organization_id: currentOrganization?.id,
      message: {
        subject: messageSubject,
        body: messageContent,
        event_id: event.id,
        user_id: user?.id,
        additional_options: Object.keys(additionalOptions).length ? additionalOptions : undefined,
      },
    };

    makeApiRequest({
      path: `/vendor/messages`,
      method: "POST",
      params,
    })
      .then((res) => {
        if (res.status === 201) {
          showSuccess("Message sent successfully");
          setShowingModal(false);
        } else {
          showError(res.data.message);
        }
      })
      .catch((error) => {
        showError(error.response.data.message);

      });

  };

  const handleViewMessageContent = (message: EventMessage) => {
    setModalContent(message);
    setShowingModal(true);
  };

  const handleMessageResend = (message: EventMessage) => {
    makeApiRequest({
      path: `/vendor/messages/${message.id}/resend`,
      method: "POST",
      params: {
        organization_id: currentOrganization?.id,
      },
    }).then((res) => {
      if (res.status === 201) {
        showSuccess("Message resent successfully");
      } else {
        showError("Failed to resend message");
      }
    })
      .catch((error) => {
        showError(error.response.data.message);
      });
  };

  const { data } = useQuery({
    queryKey: ["messages", event.id],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/messages`,
        params: {
          organization_id: currentOrganization?.id,
        },
      }),
    enabled: !!currentOrganization,
  });

  const messages: EventMessage[] = data?.data || [];

  return (
    <>
      <VendorCard className="mt-5">
        <div className="flex justify-between items-center">
          <h2 className="font-bold">Messages</h2>
          <Button variant="blue" size="sm" onClick={showNewMessageModal}>
            <PlusIcon className="h-4 w-4" />
            New Message
          </Button>
        </div>
        {messages.length === 0 ? (
          <div className="w-full flex items-center justify-center">
            <div className="max-w-lg py-5 px-16 flex flex-col gap-2 justify-center items-center">
              <EnvelopeIcon className="h-10 text-gray-300" />
              <span className="text-xs font-bold">No Messages Yet</span>
            </div>
          </div>
        ) : (
          <MessageTable messages={messages} handleMessageResend={handleMessageResend} handleViewMessageContent={handleViewMessageContent} />
        )}
      </VendorCard>

      <Modal
        title={modalContent === "new" ? "New Message" : "Message Content"}
        isOpen={showingModal}
        onRequestClose={() => setShowingModal(false)}
      >
        {modalContent === "new" && (
          <div className="min-h-[400px] flex flex-col">
            {/* Tier Select */}
            <label className="font-bold mt-4">Tier</label>
            <select
              className="border border-gray-300 mt-1"
              onChange={(e) => setSelectedTier(e.target.value || null)}
              value={selectedTier || ""}
            >
              <option value="">All Tiers ({tiers.length})</option>
              {tiers.map((tier) => {
                const tierSections = sections.filter((s) => s.tier_id === tier.id);
                return (
                  <option key={tier.id} value={tier.id}>
                    {tier.name}
                  </option>
                );
              })}
            </select>

            {/* Section Select */}
            {selectedTier && (
              <>
                <label className="font-bold mt-4">Section</label>
                <select
                  className="border border-gray-300 mt-1"
                  onChange={(e) => setSelectedSection(e.target.value || null)}
                  value={selectedSection || ""}
                >
                  <option value="">
                    All {tiers.find((t) => t.id === Number(selectedTier))?.name} Sections ({filteredSections.length})
                  </option>
                  {filteredSections.map((section) => (
                    <option key={section.id} value={section.id}>
                      {section.name}
                    </option>
                  ))}
                </select>
              </>
            )}

            {/* Price Level Select */}
            {selectedSection && (
              <>
                <label className="font-bold mt-4">Price Level</label>
                <select
                  className="border border-gray-300 mt-1"
                  onChange={(e) => setSelectedPriceLevel(e.target.value || null)}
                  value={selectedPriceLevel || ""}
                >
                  <option value="">
                    All  {sections.find((s) => s.id === Number(selectedSection))?.name} Price Levels ({filteredPriceLevels.length})
                  </option>
                  {filteredPriceLevels.map((level) => (
                    <option key={level.id} value={level.id}>
                      {level.name}
                    </option>
                  ))}
                </select>
              </>
            )}

            {/* Subject Input */}
            <label className="font-bold mt-3">Subject</label>
            <input
              onChange={(e) => setMessageSubject(e.target.value)}
              type="text"
              className="border border-gray-300 mt-1"
            />


            {/* Message Input */}
            <label className="font-bold mt-4">Message</label>
            <RichTextEditor onChange={setMessageContent} />

            {/* Send Button */}
            <Button onClick={sendMessage} className="mt-16" variant="success">
              Send Message
              <PaperAirplaneIcon className="h-4 w-4" />
            </Button>
          </div>
        )}

        {typeof modalContent === 'object' && (
          <div className="min-h-[400px] flex flex-col gap-3">
            <div className="flex justify-between items-center">
              <h2 className="font-bold">
                Subject: {modalContent.subject}
              </h2>
            </div>
            <div>
              <div dangerouslySetInnerHTML={{
                __html: sanitizeHTML(modalContent.body),
              }}
              />
            </div>
          </div>
        )}
      </Modal>


    </>
  );
};

export const MessageTable = ({
  messages,
  handleViewMessageContent,
  handleMessageResend
}: {
  messages: EventMessage[] | [];
  handleViewMessageContent: (message: EventMessage) => void;
  handleMessageResend: (message: EventMessage) => void;
}) => {
  const columnHelper = createColumnHelper<EventMessage>();

  const columns = [
    columnHelper.accessor("subject", {
      header: "Subject",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("sent_by", {
      header: "Sent By",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("created_at", {
      header: "Created At",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("recipient_count", {
      header: "Recipients",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        const eventMessage = props.row.original;
        return (
          <div className="flex gap-3 justify-end">
            <Button
              onClick={() => {
                if (window.confirm("Are you sure you want to resend this message?")) {
                  handleMessageResend(eventMessage);
                }
              }}
              size="sm"
              variant="default"
            >
              <PaperAirplaneIcon className="h-4 w-4" />
              Resend
            </Button>
            <Button onClick={() => handleViewMessageContent(eventMessage)} size="sm" variant="purple">View Content</Button>
          </div>
        )
      }
    })
  ];


  const table = useReactTable({
    data: messages,
    columns,
    getCoreRowModel: getCoreRowModel(),
  }) as Table<Resources>;

  return (
    <div>
      <VendorTable table={table} />
    </div>
  );
};