import { useQuery } from "@tanstack/react-query";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line, Doughnut } from "react-chartjs-2";
import { useVendor } from "../../../providers/VendorContext";
import { makeApiRequest } from "../../../utils/api";
import { HappyTooltip } from "../../../components/reusable/Tooltip";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { OrdersTable } from "../Events/EventDetails";

import {
  formatCentsToDollars,
  formatCentsToReadableString,
} from "../../../utils/currency";
import { ArrowUpIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../../utils/styles";
import { ArrowDownIcon, CameraIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { Button } from "../../../components/reusable/Button";
import {
  LinkIcon,
  MapPinIcon,
  PencilIcon,
} from "@heroicons/react/24/solid";
import { SketchPicker } from "react-color";
import { useState, useEffect } from "react";
import { Modal } from "../../../components/reusable/Modal";
import { Field, FieldInputProps, Form, Formik, useFormikContext, FormikProps } from "formik";
import { SelectInput, TextInput } from "../../../components/reusable/Form";
import { timezoneOptions } from "../../../utils/timezones";
import { useNotification } from "../../../providers/NotificationContext";
import { IoInformationCircle } from "react-icons/io5";
import { dataURLtoBlob } from "../../../utils/images";
import Select from "react-select";
import { StateSelect } from "../../../components/reusable/StateSelect";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

type RevenueByDay = {
  day: string;
  revenue: number;
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "bottom" as const,
    },
    title: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value: unknown) {
          return "$" + value;
        },
      },
    },
  },
};

type DashboardData = {
  event_id: number;
  event_name: string;
  total_revenue_cents: number;
  percentage_difference_in_total_orders: string;
  percentage_difference_in_tickets_sold: string;
  percentage_difference_in_total_revenue: string;
  start_date: string;
  end_date: string;
  total_balance: number;
  total_base_price_all_time: number;
  total_happy_fees_this_month: number;
  total_payouts_all_time: number;
  total_processing_fees_this_month: number;
  total_taxes_all_time: number;
};

export type OrganizationFormValues = {
  name: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zip: string;
  logo: Blob | null;
  contact_email: string;
  contact_phone: string;
  timezone_name: string;
  primary_color: string;
  secondary_color: string;
  add_tax_at_checkout: boolean;
  payment_methods: { value: string, label: string }[];
  [key: string]: string | number | boolean | Date | null | Blob | string[] | { value: string, label: string }[];
};

export type PhotoUploadProps = {
  fieldName: string;
  label: string;
  initialImageUrl?: string;
};

const PAYMENT_METHOD_OPTIONS = [
  { value: "cash", label: "Cash" },
  { value: "credit_card", label: "Credit Card" }
];

export const Dashboard = () => {
  const { showSuccess, showError } = useNotification();
  const { currentOrganization, refetchOrganization } = useVendor();
  const [editingOrganization, setEditingOrganization] = useState(false);
  const { data: recentOrderData } = useQuery({
    queryKey: ["recent_orders", currentOrganization],
    queryFn: () => {
      return makeApiRequest({
        path: `/vendor/orders`,
        params: { organization_id: currentOrganization?.id },
      });
    },
    enabled: !!currentOrganization,
    retry: false,
  });
  const recentOrders = recentOrderData?.data || [];

  const { data: dashboardData } = useQuery({
    queryKey: ["dashboard", currentOrganization],
    queryFn: () => {
      return makeApiRequest({
        path: `/vendor/organizations/${currentOrganization?.id}/dashboard`,
        params: { organization_id: currentOrganization?.id },
      });
    },
    enabled: !!currentOrganization,
    retry: false,
  });

  const total_revenue_this_month =
    dashboardData?.data.total_revenue_this_month || 0;
  const total_orders_this_month =
    dashboardData?.data.total_orders_this_month || 0;
  const tickets_sold_this_month =
    dashboardData?.data.tickets_sold_this_month || 0;
  const total_revenue_by_day = dashboardData?.data.total_revenue_by_day || [];
  const labels = total_revenue_by_day.map((item: RevenueByDay) => item.day);
  console.log(dashboardData);
  const chartData = {
    labels,
    datasets: [
      {
        fill: {
          target: "origin",
          above: "rgba(76, 201, 240, 0.45)", // And blue below the origin
        },
        label: "Revenue",
        data: total_revenue_by_day
          ? total_revenue_by_day.map((item: RevenueByDay) => item.revenue / 100)
          : [],
        backgroundColor: "#4cc9f0",
      },
    ],
  };
  if (!currentOrganization) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col sm:flex-row sm:justify-between mb-4">
        <div className="flex items-center justify-between gap-4 w-full">
          <div className="flex gap-4 items-center">
            <img
              src={
                currentOrganization?.image_url ||
                "//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-black.svg"
              }
              alt="Organization Logo"
              className="w-20 h-auto rounded-md"
            />
            <div className="flex flex-col gap-1">
              <h1 className="text-2xl font-bold text-gray-500 flex items-center gap-2">
                {currentOrganization?.name}
              </h1>
              <span className="flex gap-2 items-center">
                <MapPinIcon className="w-5" />
                <span>
                  {currentOrganization?.city && currentOrganization?.state ? (
                    <span>
                      {currentOrganization?.city}, {currentOrganization?.state}
                    </span>
                  ) : (
                    <button
                      onClick={() => setEditingOrganization(true)}
                      className="text-blue font-bold text-sm"
                    >
                      Add City
                    </button>
                  )}
                </span>
              </span>
            </div>
          </div>
          <div>
            <Menu as="div" className="relative">
              <MenuButton>
                <Button className="flex items-center gap-3 text-sm font-bold">
                  Actions
                  <ChevronDownIcon className="w-4" />
                </Button>
              </MenuButton>
              <MenuItems className="absolute z-30 bg-white right-0 w-60 rounded-lg mt-2 shadow-lg">
                <MenuItem>
                  {({ active }) => (
                    <button
                      onClick={() => setEditingOrganization(true)}
                      className={`${active ? "bg-gray-50" : ""
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    >
                      <PencilIcon className="w-3 h-3 mr-2" />
                      Edit Organization
                    </button>
                  )}
                </MenuItem>
                <MenuItem>
                  {({ active }) => (
                    <a
                      href={`${import.meta.env.VITE_CONSUMER_WEB_URL}/orgs/${currentOrganization?.id
                        }`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`${active ? "bg-gray-50" : ""
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    >
                      <LinkIcon className="w-3 h-3 mr-2" />
                      View Public Organization Page
                    </a>
                  )}
                </MenuItem>
              </MenuItems>
            </Menu>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-3 md:flex-row md:gap-6 w-full">
          <VendorCard className="w-full md:w-1/3">
            <div className="mx-auto flex max-w-xs flex-col gap-y-4 items-center justify-center h-full">
              <dt className="font-bold text-base leading-7 text-gray-500">
                Orders Completed
                <HappyTooltip
                  content="The total number of orders completed the last month"
                  anchor='top'
                >
                  <IoInformationCircle className="w-5 h-4 -mb-1 text-gray-400" />
                </HappyTooltip>
              </dt>
              <dd className="relative order-first text-3xl font-semibold tracking-tight text-lightPurple sm:text-4xl">
                {total_orders_this_month}
                {total_orders_this_month > 0 && (
                  <span
                    className={classNames(
                      "absolute text-sm bottom-0 ml-1 flex -right-14",
                      dashboardData?.data.percentage_difference_in_total_orders.includes(
                        "-"
                      )
                        ? "text-red-500"
                        : "text-green-500"
                    )}
                  >
                    {dashboardData?.data.percentage_difference_in_total_orders.includes(
                      "-"
                    ) ? (
                      <ArrowDownIcon className="w-4" />
                    ) : (
                      <ArrowUpIcon className="w-4" />
                    )}
                    {dashboardData?.data.percentage_difference_in_total_orders.substring(
                      1
                    )}
                  </span>
                )}
              </dd>
              <span className="text-gray-400 text-sm">
                {dashboardData?.data.start_date}
                {" - "}
                {dashboardData?.data.end_date}
              </span>
            </div>
          </VendorCard>
          <VendorCard className="w-full md:w-1/3">
            <div className="mx-auto flex max-w-xs flex-col gap-y-4 items-center justify-center h-full">
              <dt className="font-bold text-base leading-7 text-gray-500">
                Tickets Sold
                <HappyTooltip
                  content="The total number of tickets sold the last month"
                  anchor='top'
                >
                  <IoInformationCircle className="w-5 h-4 -mb-1 text-gray-400" />
                </HappyTooltip>
              </dt>
              <dd className="relative order-first text-3xl font-semibold tracking-tight text-lightPurple sm:text-4xl">
                {tickets_sold_this_month}
                {tickets_sold_this_month > 0 && (
                  <span
                    className={classNames(
                      "absolute text-sm bottom-0 ml-1 flex -right-14",
                      dashboardData?.data.percentage_difference_in_tickets_sold.includes(
                        "-"
                      )
                        ? "text-red-500"
                        : "text-green-500"
                    )}
                  >
                    {dashboardData?.data.percentage_difference_in_tickets_sold.includes(
                      "-"
                    ) ? (
                      <ArrowDownIcon className="w-4" />
                    ) : (
                      <ArrowUpIcon className="w-4" />
                    )}
                    {dashboardData?.data.percentage_difference_in_tickets_sold.substring(
                      1
                    )}
                  </span>
                )}
              </dd>
              <span className="text-gray-400 text-sm">
                {dashboardData?.data.start_date}
                {" - "}
                {dashboardData?.data.end_date}
              </span>
            </div>
          </VendorCard>
          <VendorCard className="w-full md:w-1/3">
            <div className="mx-auto flex max-w-xs flex-col gap-y-4 items-center justify-center h-full">
              <dt className="font-bold text-base leading-7 text-gray-500">
                Net Sales
                <HappyTooltip
                  content="Your base ticket revenue, minus any cash or refunds, from sales for the last month"
                  anchor='top'
                >
                  <IoInformationCircle className="w-5 h-4 -mb-1 text-gray-400" />
                </HappyTooltip>
              </dt>
              <dd className="relative order-first text-3xl font-semibold tracking-tight text-lightPurple sm:text-4xl">
                {formatCentsToReadableString(total_revenue_this_month)}
                {total_revenue_this_month > 0 && (
                  <span
                    className={classNames(
                      "absolute text-sm bottom-0 ml-1 flex -right-14",
                      dashboardData?.data.percentage_difference_in_total_revenue.includes(
                        "-"
                      )
                        ? "text-red-500"
                        : "text-green-500"
                    )}
                  >
                    {dashboardData?.data.percentage_difference_in_total_revenue.includes(
                      "-"
                    ) ? (
                      <ArrowDownIcon className="w-4" />
                    ) : (
                      <ArrowUpIcon className="w-4" />
                    )}
                    {dashboardData?.data.percentage_difference_in_total_revenue.substring(
                      1
                    )}
                  </span>
                )}
              </dd>
              <span className="text-gray-400 text-sm">
                {dashboardData?.data.start_date}
                {" - "}
                {dashboardData?.data.end_date}
              </span>
            </div>
          </VendorCard>
        </div>
        <VendorCard>
          <div className="flex flex-col md:flex-row gap-5">
            <div className="w-full md:w-1/2">
              <span className="text-lg block mb-4">
                Sales{" "}
                <span className="text-gray-400 text-sm">{`(${dashboardData?.data.start_date} - ${dashboardData?.data.end_date})`}</span>
              </span>
              <Line data={chartData} options={options} />
            </div>
            <div className="w-full md:w-1/2">
              <span className="text-lg">
                Earnings{" "}
                <span className="text-gray-400 text-sm">(All Time)</span>
              </span>
              <div className="flex gap-1 items-center mt-4">
                <div className="w-1/2">
                  <Donut dashboardData={dashboardData?.data} />
                </div>
                <div className="w-1/2 flex flex-col gap-2 pl-3">
                  <span className="text-sm flex gap-2 items-center">
                    <span className="block rounded-full bg-lightPurple w-3 h-3" />
                    <span className="font-bold">
                      {formatCentsToDollars(
                        dashboardData?.data.total_base_price_all_time
                      )}
                    </span>
                    <span className="text-gray-400">Net Sales</span>
                  </span>
                  {currentOrganization?.add_tax_at_checkout && (
                    <span className="text-sm flex gap-2 items-center">
                      <span className="block rounded-full bg-blue w-3 h-3" />
                      <span className="font-bold">
                        {formatCentsToDollars(
                          dashboardData?.data.total_taxes_all_time
                        )}
                      </span>
                      <span className="text-gray-400">Taxes</span>
                    </span>
                  )}
                  <span className="text-sm flex gap-2 items-center">
                    <span className="block rounded-full bg-pink w-3 h-3" />
                    <span className="font-bold">
                      {formatCentsToDollars(
                        dashboardData?.data.total_payouts_all_time
                      )}
                    </span>
                    <span className="text-gray-400">Payouts</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </VendorCard>
        <VendorCard>
          <h3 className="text-lg font-bold text-gray-500">
            Recent Orders
          </h3>
          {recentOrders?.length > 0 ? (
            <OrdersTable orders={recentOrders} />
          ) : (
            <div className="flex justify-center items-center h-32">
              <span className="font-medium text-gray-600">No Recent Orders</span>
            </div>
          )}
        </VendorCard>
      </div>

      <Modal
        onRequestClose={() => setEditingOrganization(false)}
        isOpen={editingOrganization}
        title="Edit Organization"
      >
        <>
          <Formik
            onSubmit={(data) => {
              const formData = new FormData();
              formData.append("organization[name]", data.name);
              formData.append("organization[timezone_name]", data.timezone_name);
              formData.append("organization[city]", data.city);
              formData.append("organization[address_1]", data.address_1);
              formData.append("organization[address_2]", data.address_2 || "");
              formData.append("organization[state]", data.state);
              formData.append("organization[zip]", data.zip);
              formData.append("organization[contact_phone]", data.contact_phone);
              formData.append("organization[contact_email]", data.contact_email);
              formData.append("organization[primary_color]", data.primary_color);
              formData.append("organization[secondary_color]", data.secondary_color);
              formData.append("organization[add_tax_at_checkout]", data.add_tax_at_checkout.toString());
              data.payment_methods.forEach((method) => {
                formData.append("organization[payment_methods][]", method);
              });

              // Only append the logo if it exists
              if (data.logo) {
                formData.append("organization[logo]", data.logo);
              }
              makeApiRequest({
                path: `/vendor/organizations/${currentOrganization?.id}`,
                method: "PUT",
                hasFormData: true,
                params: formData,


              }).then((res) => {
                if (res.status === 200) {
                  showSuccess("Organization updated successfully");
                  setEditingOrganization(false);
                  refetchOrganization();
                }
              })
                .catch((err) => {
                  const message = err.response.data.message;
                  showError(message);
                });
            }}

            initialValues={{
              name: currentOrganization?.name,
              timezone_name: currentOrganization?.timezone_name,
              city: currentOrganization?.city,
              address_1: currentOrganization?.address_1,
              address_2: currentOrganization?.address_2,
              state: currentOrganization?.state,
              zip: currentOrganization?.zip,
              logo: null,
              contact_phone: currentOrganization?.contact_phone,
              contact_email: currentOrganization?.contact_email,
              primary_color: currentOrganization?.primary_color || "#ffffff",
              secondary_color:
                currentOrganization?.secondary_color || "#ffffff",
              add_tax_at_checkout: currentOrganization?.add_tax_at_checkout || false,
              payment_methods: currentOrganization?.payment_methods || ["cash", "credit_card"],
            }}
          >
            <Form className="flex flex-col gap-4">
              <div className="w-full flex gap-4 items-end">
                <Field name="name">
                  {({ field }: { field: FieldInputProps<never> }) => (
                    <>
                      <TextInput
                        label="Organization Name"
                        type="text"
                        {...field}
                      />
                    </>
                  )}
                </Field>
                <Field name="logo">
                  {() => (
                    <PhotoUpload
                      fieldName="logo"
                      label="Logo"
                      initialImageUrl={currentOrganization?.image_url}
                    />
                  )}
                </Field>
              </div>


              <div className="flex gap-4 mt-8">
                <div className="w-1/2">
                  <Field name="contact_email">
                    {({ field }: { field: FieldInputProps<never> }) => (
                      <>
                        <TextInput
                          label="Email Address"
                          placeholder="Email Address"
                          type="text"
                          {...field}
                        />
                      </>
                    )}
                  </Field>
                </div>

                <div className="w-1/2">
                  <Field name="timezone_name">
                    {({ field }: { field: FieldInputProps<never> }) => (
                      <SelectInput
                        initialValue={{
                          value: currentOrganization?.timezone_name || "",
                          display: currentOrganization?.timezone_name || "",
                        }}
                        onSelect={(option) => {
                          field.onChange({
                            target: { name: field.name, value: option.value },
                          });
                        }}
                        label="Timezone"
                        options={timezoneOptions}
                        {...field}
                      />
                    )}
                  </Field>
                </div>
              </div>


              <div className="flex gap-4">
                <div className="w-1/2">
                  <Field name="address_1">
                    {({ field }: { field: FieldInputProps<never> }) => (
                      <>
                        <TextInput
                          label="Address"
                          type="text"
                          {...field}
                        />
                      </>
                    )}
                  </Field>
                </div>

                <div className="w-1/2">
                  <Field name="address_2">
                    {({ field }: { field: FieldInputProps<never> }) => (
                      <>
                        <TextInput
                          label="Address 2 (Optional)"
                          type="text"
                          {...field}
                        />
                      </>
                    )}
                  </Field>
                </div>
              </div>

              <div className="flex gap-4">
                <div className="w-1/2">
                  <Field name="city">
                    {({ field }: { field: FieldInputProps<never> }) => (
                      <>
                        <TextInput
                          label="City"
                          type="text"
                          {...field}
                        />
                      </>
                    )}
                  </Field>
                </div>

                <div className="w-1/2">
                  <StateSelect name="state" />
                </div>
              </div>

              <div className="flex gap-4">
                <div className="w-1/2">
                  <Field name="zip">
                    {({ field }: { field: FieldInputProps<never> }) => (
                      <>
                        <TextInput
                          label="Zip"
                          type="text"
                          {...field}
                        />
                      </>
                    )}
                  </Field>
                </div>

                <div className="w-1/2">
                  <Field name="contact_phone">
                    {({ field }: { field: FieldInputProps<never> }) => (
                      <>
                        <TextInput
                          label="Phone Number"
                          type="text"
                          {...field}
                        />
                      </>
                    )}
                  </Field>
                </div>
              </div>

              <div className="w-full">
                <label className="mb-2 block">Payment Methods</label>
                <Field name="payment_methods">
                  {({ field, form }: { field: FieldInputProps<string[]>, form: FormikProps<OrganizationFormValues> }) => (
                    <Select
                      isMulti
                      options={PAYMENT_METHOD_OPTIONS}
                      value={PAYMENT_METHOD_OPTIONS.filter(option =>
                        field.value.includes(option.value)
                      )}
                      onChange={(selectedOptions) => {
                        form.setFieldValue(
                          "payment_methods",
                          selectedOptions.map(option => option.value)
                        );
                      }}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  )}
                </Field>
              </div>

              <div className="flex items-center gap-2 mt-4">
                <Field
                  type="checkbox"
                  name="add_tax_at_checkout"
                  className="h-4 w-4 rounded border-gray-300 text-lightPurple focus:ring-lightPurple"
                />
                <label htmlFor="add_tax_at_checkout" className="text-sm text-gray-700">
                  Add Tax at Checkout
                </label>
                <HappyTooltip
                  content="When enabled, tax will be calculated and automatically added to the total at checkout"
                  anchor="top"
                >
                  <IoInformationCircle className="w-5 h-4 text-gray-400" />
                </HappyTooltip>
              </div>


              <div className="flex gap-4">
                <div className="w-1/2">
                  <ColorPickerField
                    name="primary_color"
                    label="Primary Color"
                  />
                </div>

                <div className="w-1/2">
                  <ColorPickerField
                    name="secondary_color"
                    label="Secondary Color"
                  />
                </div>
              </div>


              <Button type="submit" variant="blue" size="lg">
                Save Changes
              </Button>
            </Form>
          </Formik>
        </>
      </Modal>
    </>
  );
};

export const ColorPickerField = ({
  name,
  label,
}: {
  name: string;
  label: string;
}) => {
  const { setFieldValue, values } = useFormikContext<{
    [key: string]: string;
  }>();

  return (
    <div className="mb-4">
      <label className="mb-2 block">{label}</label>
      <SketchPicker
        color={values[name]}
        onChangeComplete={(color) => {
          setFieldValue(name, color.hex);
        }}
      />

      <div className="w-32 mt-2">
        <TextInput
          name={name}
          type="text"
          value={values[name]}
          onChange={(e) => setFieldValue(name, e.target.value)}
          placeholder="#FFFFFF"
        />
      </div>
    </div>
  );
};

const Donut = ({ dashboardData }: { dashboardData?: DashboardData }) => {
  const { currentOrganization } = useVendor();
  let labels = ["Base Price", "Payouts"];
  let backgroundColors = ["#BB53FF", "#f72585"];
  let dashboardDataArray = [
    dashboardData?.total_base_price_all_time,
    dashboardData?.total_payouts_all_time,
  ];
  if (currentOrganization?.add_tax_at_checkout) {
    labels = ["Base Price", "Taxes", "Payouts"];
    dashboardDataArray = [
      dashboardData?.total_base_price_all_time,
      dashboardData?.total_taxes_all_time,
      dashboardData?.total_payouts_all_time,
    ];
    backgroundColors = ["#BB53FF", "#4361ee", "#f72585"];
  }

  const data = {
    labels,
    datasets: [
      {
        data: dashboardDataArray,
        backgroundColor: backgroundColors,
      },
    ],
  };

  return (
    <div className="relative">
      <Doughnut
        data={data}
        options={{
          responsive: true,
          cutout: 80,
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
      <span className="flex flex-col justify-center items-center absolute top-0 right-0 bottom-0 left-0 m-auto z-10">
        <span className="font-bold text-lg">
          {formatCentsToDollars(dashboardData?.total_balance || 0)}
        </span>
        <span className="text-gray-400">
          Balance
          <HappyTooltip
            content="Your total net sales to date, minus all payouts issued to date"
            anchor='top'
          >
            <IoInformationCircle className="w-5 h-4 -mb-1 text-gray-400" />
          </HappyTooltip>
        </span>
      </span>
    </div>
  );
};

const PhotoUpload: React.FC<PhotoUploadProps> = ({
  fieldName,
  label,
  initialImageUrl,
}) => {
  const [imagePreview, setImagePreview] = useState<string | null>(
    initialImageUrl || null
  );
  const formik = useFormikContext<OrganizationFormValues>();

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const image = reader.result as string;
        setImagePreview(image);
        const blob: Blob = dataURLtoBlob(image);
        formik.setFieldValue(fieldName, blob);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (initialImageUrl) {
      setImagePreview(initialImageUrl);
    }
  }, [initialImageUrl]);

  return (
    <div className="relative h-20 w-1/2">
      <input
        id={`file-upload-${fieldName}`}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleImageUpload}
      />
      {imagePreview ? (
        <label
          htmlFor={`file-upload-${fieldName}`}
          className="absolute rounded-md left-1/2 -translate-x-1/2 -translate-y-3 cursor-pointer"
        >
          {label}
          <img
            src={imagePreview}
            alt={label}
            className="border border-gray-400 p-3 w-28 object-cover"
          />
        </label>
      ) : (
        <div>
          <label
            htmlFor={`file-upload-${fieldName}`}
            className="hover:brightness-125 cursor-pointer flex gap-2 justify-center text-lightPurple rounded-lg"
          >
            <CameraIcon className="w-5 text-white" /> {label}
          </label>
        </div>
      )}
    </div>
  );
};


