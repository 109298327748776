// src/components/RichTextEditor.tsx

import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles for the editor

interface RichTextEditorProps {
  placeholder?: string;
  onChange?: (content: string) => void;
  value?: string; // New prop to accept initial content
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  placeholder = "Type something...",
  onChange,
  value = '', // Default value
}) => {
  const [editorContent, setEditorContent] = useState<string>(value);

  // Update internal state when the value prop changes
  useEffect(() => {
    setEditorContent(value);
  }, [value]);

  // Quill modules for customizing the toolbar
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean'], // Remove formatting button
    ],
  };

  // Quill formats to specify what features are supported
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'link',
  ];

  const handleContentChange = (content: string) => {
    setEditorContent(content);
    if (onChange) {
      onChange(content);
    }
  };

  return (
    <ReactQuill
      value={editorContent}
      onChange={handleContentChange}
      modules={modules}
      formats={formats}
      placeholder={placeholder}
      style={{ height: '200px' }}
    />
  );
};

export default RichTextEditor;
